import React from "react"
import * as styles from "./index.module.scss"

const Breadcrumbs = ({ title = null, titleAlt = null }) => {
  if (!title && !titleAlt) return null
  return (
    <section className={styles.breadcrumbs_wrapper}>
      <div className="container">
        {title && <h1>{title}</h1>}
        {titleAlt && <span>{titleAlt}</span>}
      </div>
    </section>
  )
}
export default Breadcrumbs
